import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const Video = ({ url }) => {
  if (!url) {
    return null;
  }

  return (
    <Container>
      <ReactPlayer
        url={url}
        className="react-player"
        width="auto"
        height="auto"
        light
        controls
        playing
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  .react-player {
    aspect-ratio: 1.77;
    width: auto;
    height: 100%;
  }
`;

export default Video;
