import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';
import Sections from '@components/Sections';
import Video from '@components/Video';
import Image from '@core/Image';

const VitraPage = ({
  data: {
    contentfulVitraPage: {
      title,
      subtitle,
      image,
      imageCaption,
      description,
      videoCaption,
      videoUrl,
      sections,
    },
  },
}) => (
  <>
    <Seo pageTitle={title} pageDescription={subtitle} />
    <Main>
      <TopSection>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <ImageContainer>
          <Image image={image} />
          <ImageCaption text={imageCaption} />
        </ImageContainer>
        <Description text={description} />
      </TopSection>
      <VideoContainer>
        <div>
          <Video url={videoUrl} />
          <RichText text={videoCaption} />
        </div>
      </VideoContainer>
      <Sections sections={sections} />
    </Main>
  </>
);

const defaultTextColumns = css`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const Main = styled.main`
  [class*='AlternateSlideshowSection__Subtitle'] {
    font: var(--font-body-mobile);

    ${mq.small} {
      font: var(--font-m);
    }
  }

  [class*='ImageTextSection__Container'] {
    padding-bottom: 0;
    padding-top: 0;
  }

  [class*='ImageTextSection__Text'] {
    margin-top: 0;
  }

  [class*='LinkSection__Container'] {
    margin-top: 2rem;
  }
`;

const TopSection = styled.section`
  padding-bottom: 0;

  ${mqdown.small} {
    &:first-child {
      padding-top: var(--space-xs);
    }
  }
`;

const Title = styled.h1`
  ${defaultTextColumns};
  margin-bottom: var(--space-xs);
  font: var(--font-l);

  ${mq.small} {
    margin-bottom: var(--space-s);
    font: var(--font-xl);
    letter-spacing: var(--letter-spacing-xl);
  }
`;

const Subtitle = styled.h2`
  ${defaultTextColumns};
  margin-bottom: var(--space-xs);

  ${mq.small} {
    margin-bottom: var(--space-s);
    font: var(--font-l);
  }
`;

const Description = styled(RichText)`
  ${defaultTextColumns};
  text-align: center;
`;

const ImageContainer = styled.div`
  ${defaultTextColumns};

  display: grid;
  column-gap: 3rem;
  row-gap: 2rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${mq.small} {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
`;

const ImageCaption = styled(RichText)`
  blockquote {
    p {
      position: relative;
      display: inline;

      &::before {
        content: '';
        position: absolute;
        left: -0.6rem;
        top: -0.6rem;
        height: 2.625rem;
        width: 2.625rem;
        border-radius: 50%;
        background-color: var(--color-primary);
        transition: background-color var(--motion-double);
        z-index: -1;

        ${mq.small} {
          left: -0.5rem;
          top: -0.5rem;
          width: 3.25rem;
          height: 3.25rem;
        }
      }
    }
  }

  > p {
    font: var(--font-xs);

    ${mq.small} {
      font: var(--font-s);
    }
  }
`;

const VideoContainer = styled.section`
  > div {
    ${defaultTextColumns};

    display: grid;
    column-gap: 3rem;
    row-gap: 2rem;
    text-align: center;

    ${mq.small} {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      font: var(--font-s);

      > div:last-child {
        order: -1;
        justify-self: end;
      }
    }
  }

  h2 {
    /* font: var(--font-s); */
    text-align: center;

    ${mq.small} {
      /* font: var(--font-xm); */
    }
  }
`;

export const VitraPageQuery = graphql`
  query VitraPageQuery($locale: String) {
    contentfulVitraPage(node_locale: { eq: $locale }) {
      node_locale
      title
      subtitle
      description {
        raw
      }
      videoCaption {
        raw
      }
      image {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          quality: 75
        )
      }
      imageCaption {
        raw
      }
      videoUrl
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(VitraPage);
